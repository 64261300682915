import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import Container from '../components/Base/Container/PageContainer';
import Layout from '../components/Base/Layout';
import Links from '../components/Base/Links';
import SEO from '../components/Base/seo';

const AuthorHeader = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  max-height: 12.8rem;
  margin-bottom: 2rem;
  height: 100%;
  width: 100%;
`;

const AuthorText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2rem;
`;

const Heading = styled.h1`
  font-size: 3rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 1.5rem;
  }
  @media ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 2rem;
  }
`;

const Description = styled.div`
  font-size: 2rem;
  padding-bottom: 2rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 1.2rem;
  }
  @media ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 1.5rem;
  }
`;

const Avatar = styled(Img)`
  border-radius: 50%;
  height: 100%;
  width: 100%;
  // @media ${({ theme }) => theme.breakpoints.mobile} {
  //   height: 5rem;
  //   width: 5rem;
  // }
  // @media ${({ theme }) => theme.breakpoints.tablet} {
  //   font-size: 1.5rem;
  // }
  max-height: 12.8rem;
  max-width: 12.8rem;
`;

const Gallery = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: -1rem;
`;

const GalleryItem = styled(props => <Links {...props} />)`
  /* margin: 1rem; */
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex: 1 0 100%;
  }
  @media ${({ theme }) => theme.breakpoints.nonmobile} {
    flex: 0 0 50%;
  }
  padding: 1rem;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.blackText};
`;

const ImgStyled = styled(Img)`
  @media ${({ theme }) => theme.breakpoints.mobile} {
    height: 15rem;
  }
  @media ${({ theme }) => theme.breakpoints.nonmobile} {
    height: 20rem;
  }
`;

const CardTextBlock = styled.div`
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
`;
const CardTitle = styled.h2`
  font-size: 1.5rem;
  line-height: 2rem;
  max-height: 4rem;
  padding-bottom: 0.5rem;
`;
const CardSubtitle = styled.h3`
  font-size: 1rem;
  line-height: 1.5rem;
  max-height: 3rem;
  font-weight: 400;
`;

const AuthorsPage = ({ data, pageContext: { name, bio, avatar, slug: authorSlug } }) => {
  const authorPosts = data?.posts;
  const { edges: posts = [] } = authorPosts;
  return (
    <Layout>
      <SEO title={`${name} | Авторы`} slug={`authors/${authorSlug}`} />
      <Container>
        <AuthorHeader>
          <Avatar fluid={avatar} />
          <AuthorText>
            <Heading>{name}</Heading>
            <Description>{bio}</Description>
          </AuthorText>
        </AuthorHeader>
        <Gallery>
          {posts.map(({ node: { title, subtitle, slug, mainImage } }) => {
            const { current } = slug;
            const {
              asset: { fluid },
            } = mainImage;
            return (
              <GalleryItem key={current} to={`/articles/${current}`}>
                <ImgStyled fluid={fluid} />
                <CardTextBlock>
                  <CardTitle>{title}</CardTitle>
                  <CardSubtitle>{subtitle}</CardSubtitle>
                </CardTextBlock>
              </GalleryItem>
            );
          })}
        </Gallery>
      </Container>
    </Layout>
  );
};

export const pageQuery = graphql`
  query AuthorQuery($id: String!) {
    posts: allSanityPost(filter: { author: { id: { eq: $id } } }) {
      edges {
        node {
          title
          subtitle
          slug {
            current
          }
          mainImage {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;

AuthorsPage.propTypes = {
  data: PropTypes.any.isRequired,
  pageContext: PropTypes.any.isRequired,
};

export default AuthorsPage;
